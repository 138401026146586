.fullWidthLayout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
  justify-content: flex-start;
  align-items: stretch;
  .containerbox {
    width: 100vw;
    max-width: 100%;
    height: 100%;
    align-self: center;
    flex: 1;
    overflow: hidden;
  }
}
