.waitlistLayout {
  padding: 12px;
  margin: 0 auto;
  min-height: 100vh;

  .content {
    max-width: 1440px;
    padding: 0 60px;
    width: 100%;
    display: flex;
    margin: auto;

    .formArea {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      .backButton {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin: 30px 30px 30px 0;
        position: absolute;
        top: 20px;
        left: 0;
        text-decoration: none;

        .icon {
          margin-right: 12px;
        }
        .backButtonText {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #0c0433;
        }
      }
      .checkboxInputArea {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #3f4047d1;

        .checkboxInput {
          margin-right: 10px;
        }
      }
      .mainArea {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 400px;
        flex: 1;
      }
    }
    .contentArea {
      width: 500px;
      height: 100%;
      background: #2f2e37;
      border-radius: 16px;
      position: relative;
      padding: 30px;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      .backgroundImage {
        position: absolute;
        top: -50px;
        left: 30%;
        transform: translateX(-50%);
        opacity: 0.8;
        max-width: 400px;
      }

      .title {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        color: #ffffff;
        // margin-bottom: 16px;
      }
      .subtitle {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 16px;
        color: #ffffff;
      }

      .card {
        border-radius: 16px;
        padding: 30px;
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        background: #f0f0f5;

        .row {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 20px;

          .testimonialAvatar {
            margin-right: 20px;
            height: 60px;
            width: 60px;
            border-radius: 50%;
          }
          .column {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .title {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 700;
              font-size: 24px;
              line-height: 36px;
              margin-bottom: 4px;
              color: #000000;
            }
            span {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 21px;
              opacity: 0.5;
              color: #444444;
            }
          }
        }
        .subtitle {
          margin-bottom: 0;
          color: #000000;
        }
      }
    }
  }
  .waitlistFooter {
    height: 50px;
    width: 100%;
    display: flex;
    max-width: 1440px;
    padding: 20px 60px 0 60px;
    align-items: center;
    justify-content: space-between;
    margin: auto;

    .copyrightText {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #3f4047;
    }
    .linksArea {
      display: flex;
      align-items: center;

      .link {
        text-decoration: none;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #3f4047;
      }
      .verticalSeparator {
        height: 20px;
        width: 2px;
        background-color: #3f4047;
        margin: 0 10px;
        opacity: 0.8;
      }
    }
  }
  @media only screen and (max-width: 1440px) {
    .content {
      width: 100%;
      padding: 0 0;
    }
  }
  @media only screen and (max-width: 1200px) {
    padding: 0;
    .content {
      flex-direction: column;
      .formArea {
        min-height: 90vh;
        margin-bottom: 20px;

        .backButton {
          left: 30px;
        }

        .waitlistFooter {
          padding-right: 0;
          margin-bottom: 10px;
          padding: 0 30px;
        }
      }
      .contentArea {
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        padding: 30px;
        border-radius: 0px;

        .backgroundImage {
          height: 300px;
        }

        .section1 {
          width: 55%;
        }
        .card {
          width: 40%;
          margin-top: 0;
          margin-left: 30px;
        }
      }
    }
  }
  @media only screen and (max-width: 700px) {
    .content {
      .formArea {
        min-height: 100vh;
        margin-bottom: 0;
        .waitlistFooter {
          padding-right: 0;
          margin-bottom: 10px;
          padding: 0 30px;
          flex-direction: column-reverse;
          align-items: center;

          .copyrightText {
            margin-top: 10px;
          }
        }
      }
      .contentArea {
        flex-direction: column;

        .backgroundImage {
          height: 300px;
        }

        .section1 {
          width: 100%;
          margin-bottom: 20px;
        }
        .card {
          width: 100%;
          margin-top: 10px;
          margin-left: 0;
        }
      }
    }
  }
  @media only screen and (max-width: 450px) {
    .content {
      .formArea {
        .waitlistFooter {
          .linksArea {
            flex-direction: column;
            .link {
              margin-top: 10px;
            }
            .verticalSeparator {
              display: none;
            }
          }
        }
        .mainArea {
          padding: 30px;
          width: 100%;
        }
      }
    }
  }
}
