.textContainer {
  padding: 50px 120px;

  .titleArea {
    .title {
      font-family: "Poppins";
      font-weight: 800;
      font-size: 32px;
      line-height: 41px;
    }
  }
  .texts {
    margin-bottom: 30px;

    margin-top: 10px;

    p {
      margin-bottom: 20px;
      color: #424451;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;

      a {
        color: #1f90ff;
        cursor: pointer;
      }
    }
    .bold {
      font-size: 16px;
      font-weight: 700px;
    }
    ol,
    ul {
      li {
        color: #424451;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 8px;
        i {
          font-weight: 600;
        }
      }
    }
    ul {
      list-style: none;
      li {
        &::before {
          content: "•";
          color: #000000;
          display: inline-block;
          width: 16px;
          margin-left: -1em;
        }
      }
    }
  }

  @media only screen and (max-width: 1000px) {
    padding: 50px 60px;
  }
  @media only screen and (max-width: 700px) {
    padding: 50px 30px;
  }
  @media only screen and (max-width: 450px) {
    padding: 50px 20px;
  }
}
