.integration-item {
  background-color: #eff6ff;
  padding: 40px;
  display: flex;
  margin-bottom: 40px;
  box-sizing: border-box;
  width: 100%;
  border-radius: 8px;
  .integration-title-container {
    width: 50%;
    margin-right: 20px;

    h2 {
      margin-bottom: 16px;
    }

    .readMoreLink {
      display: flex;
      align-items: center;
      font-family: "Poppins";
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #2563ec;
      margin-bottom: 14px;
      transition: all 0.2s linear;
      text-decoration: none;
      margin: 10px 0;

      &:hover {
        transform: translateX(2px);
      }
    }
  }
  .example-use-case-container {
    width: 50%;
    .item-title {
      margin-bottom: 10px;
      color: #2563ec;
    }
    .item-card-container {
      width: 100%;
      display: grid;
      grid-template-columns: auto auto;
      column-gap: 12px;
      row-gap: 12px;
      justify-content: start;
      .item-card {
        width: 100%;
        min-width: 230px;
        max-width: 260px;
        background-color: white;
        box-sizing: border-box;
        padding: 24px 30px;
        .caseIcon {
          height: 44px;
          margin-bottom: 10px;
        }
        .description {
          font-size: 0.8em;
        }
      }
    }
  }

  @media only screen and (max-width: 1250px) {
    .integration-title-container {
      width: 45%;
    }
    .example-use-case-container {
      width: 55%;
    }
  }

  @media only screen and (max-width: 1050px) {
    flex-wrap: wrap;
    padding: 20px;
    .integration-title-container {
      width: 100%;
      margin-bottom: 24px;
    }
    .example-use-case-container {
      width: 100%;
    }
  }

  @media only screen and (max-width: 635px) {
    flex-wrap: wrap;
    .example-use-case-container {
      .item-card-container {
        display: flex;
        flex-direction: column;
        .item-card {
          max-width: 100%;
        }
      }
    }
  }
}
