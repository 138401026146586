// import pages
@import "./pages/home";
@import "./pages/contact";
@import "./pages/termsAndPrivacy";
@import "./pages/intake";

// import components
@import "./components/navbar";
@import "./components/footer";
@import "./components/waitlistForm";
@import "./components/testimonials";
@import "./components/applications";

// import layouts
@import "./layouts/layout";
@import "./layouts/fullWidthLayout";
@import "./layouts/fullScreenLayout";
@import "./layouts/waitlistLayout";

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #adaeb6;
  }
  &::-webkit-scrollbar-thumb {
    background: #333333;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #333333;
    transform: scaleX(0.9);
  }
}

@font-face {
  font-family: "Futura";
  src: local("Futura"), url("../assets/font/futura/futura-medium-bt.ttf") format("truetype");
}
a {
  cursor: pointer;
}
