.intakePage {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 100px 120px 0 120px;
  max-width: 1440px;
  margin: 0 auto;

  .intakeForm {
    width: 100%;
    min-height: 112vh;
    height: 100%;
    border: none;
    outline: none;
  }
  @media only screen and (max-width: 1050px) {
    padding: 50px 60px 0 60px;
  }

  @media only screen and (max-width: 550px) {
    padding: 25px 30px 0 30px;
  }
}
