.RootLayout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: flex-start;
  align-items: stretch;

  .containerbox {
    width: 100vw;
    max-width: 1440px;
    align-self: center;
    flex: 1;
  }
}
