.testimonialsContainer {
  padding: 30px 120px 0px 120px;
  background-color: #dbeafe;
  display: flex;
  align-items: center;
  flex-direction: column;

  .title {
    font-family: "Poppins";
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    letter-spacing: 0.1px;
    text-align: center;
    margin-bottom: 40px;
  }

  .testimonialsGridArea {
    width: 1440px;
    padding: 0 120px;
    margin-bottom: 20px;

    .content {
      max-width: 1440px;
      border-radius: 24px;
      columns: 3;
      column-gap: 30px;

      .testimonial {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        break-inside: avoid;
        margin-bottom: 30px;

        .description {
          font-family: "Poppins";
          font-size: 14px;
          line-height: 19.6px;
          color: #1e293b;
        }
        .testimonialName {
          display: flex;
          align-items: center;
          margin-bottom: 20px;

          .testimonialAvatar {
            margin-right: 20px;
            border-radius: 50%;
            height: 40px;
            width: 40px;
          }
          .nameAndRating {
            display: flex;
            flex-direction: column;
            .stars {
              .starIcon {
                height: 14px;
                width: 14px;
              }
            }
          }
          .personName {
            font-family: "Poppins";
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #0f172a;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1440px) {
    padding: 40px 60px;
    .testimonialsGridArea {
      width: 100%;
      padding: 0 0;
    }
  }
  @media only screen and (max-width: 950px) {
    .testimonialsGridArea {
      .content {
        columns: 2;
      }
    }
  }
  @media only screen and (max-width: 600px) {
    padding: 40px 30px;
    .testimonialsGridArea {
      .content {
        columns: 1;
      }
    }
  }
  @media only screen and (max-width: 1440px) {
    .title {
      font-size: 32px;
      line-height: 38px;
    }
  }
}
