.Contact--Root {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 100px 120px;
  max-width: 1440px;
  margin: 0 auto;

  .left {
    width: 45%;
    margin: 70px 10px 0 0;
    .title {
      font-weight: 800;
      font-size: 48px;
      line-height: 61px;
      color: #0c0433;
      margin-bottom: 0;
    }
    .desc {
      color: #0c0433;
      margin-bottom: 0;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 38px;
      margin-top: 10px;
    }

    .contact__details {
      margin-top: 44px;
      .item {
        margin-top: 30px;

        &:first-child {
          margin-top: 0;
        }

        p {
          display: flex;
          align-items: center;
          color: #0c0433;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          .icon {
            margin-right: 8px;
          }
        }
      }
    }

    .social__icons {
      margin-top: 40px;
      display: flex;
      flex-direction: row;

      .icon__box {
        margin-right: 24px;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        background-color: #2563ec;
        display: grid;
        place-items: center;

        .socialIcon {
          height: 20px;
          width: 20px;
        }

        svg {
          color: #ffffff;
          font-size: 24px;
        }
      }
    }
  }
  .right {
    width: 55%;
    display: flex;
    align-items: center;
    justify-content: center;
    .calendar-widget {
      width: 100%;
      height: 1030px;
      outline: none;
      border: none;

      iframe {
        height: 100%;
      }
    }
  }

  @media only screen and (max-width: 1050px) {
    flex-direction: column;
    align-items: center;
    padding: 50px 60px;
    .left {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .right {
      width: 100%;
    }
  }

  @media only screen and (max-width: 893px) {
    .left {
      margin-bottom: 30px;
      .title {
        text-align: center;
      }
      .desc {
        text-align: center;
      }
    }
    .right {
      margin-top: 30px;
    }
  }
  @media only screen and (max-width: 550px) {
    padding: 25px 30px;

    .left {
      align-items: flex-start;
      .title {
        text-align: left;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 48px;
      }
      .desc {
        text-align: left;
      }
      .social__icons {
        margin-top: 30px;
        .icon__box {
          height: 40px;
          width: 40px;
          margin-right: 16px;
          svg {
            font-size: 20px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 460px) {
    .right {
      .calendar-widget {
        height: 1100px;
      }
    }
  }
}
