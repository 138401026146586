.accordion-item {
  border: 1px solid #ddd;
  margin-bottom: 26px;
  border-radius: 5px;
  width: 100%;
  .accordion-title {
    font-size: 24px;
    line-height: 26px;
    letter-spacing: 0.1px;
  }
  .accordion-header {
    cursor: pointer;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    span {
      font-size: 24px;
    }

    .accordionIcon {
      width: 16px;
      transition: 0.2s linear;
    }
    .activeAccordion {
      transform: rotate(180deg);
    }
  }
  .accordion-content {
    background-color: white;
    overflow: hidden;
    transition: max-height 0.5s ease; /* Smooth transition */
    p {
      padding: 15px;
      font-size: 14px;
    }
  }

  @media only screen and (max-width: 900px) {
    .accordion-title {
      font-size: 20px;
    }
  }
}
