.Footer--Root {
  background: #1e293b;
  padding: 0 122px;
  position: relative;

  .footer__container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;

    .footerHeaderContent {
      padding-top: 50px;
      display: grid;
      grid-template-columns: 48% 52%;

      .blogSection {
        display: flex;
        flex-direction: column;

        .title {
          font-family: "Poppins";
          font-weight: 600;
          font-size: 24px;
          line-height: 24px;
          letter-spacing: -0.1px;
          color: #fff;
          margin-bottom: 20px;
        }
        .row {
          display: flex;
          text-decoration: none;

          .blogImage {
            width: 260px;
            height: 140px;
            margin-right: 16px;
          }
          .column {
            display: flex;
            flex-direction: column;

            .blogTitle {
              font-family: "Poppins";
              font-weight: 600;
              font-size: 16px;
              line-height: 20px;
              letter-spacing: -0.1px;
              color: #fff;
              margin-bottom: 14px;
            }
            .blogDescription {
              font-family: "Poppins";
              font-weight: 400;
              font-size: 14px;
              line-height: 16px;
              color: #fff;
              margin-bottom: 14px;
              max-width: 400px;
            }
            .readMore {
              display: flex;
              align-items: center;
              font-family: "Poppins";
              font-weight: 600;
              font-size: 14px;
              line-height: 16px;
              color: #fff;
              margin-bottom: 14px;
              transition: all 0.2s linear;
            }
          }
          &:hover {
            .column {
              .readMore {
                transform: translateX(2px);
              }
            }
          }
        }
      }

      .links_block {
        display: flex;
        padding-left: 40px;
        width: 100%;
        box-sizing: border-box;

        .social {
          width: 40%;
        }
        .contact {
          width: 60%;
        }

        .row {
          margin-top: 16px;
          display: flex;
          align-items: center;
          .socialIcon {
            height: 20px;
            width: 20px;
            margin-right: 20px;
          }
        }

        .column {
          display: flex;
          flex-direction: column;

          .row {
            display: flex;
            align-items: center;

            .value {
              font-family: "Poppins";
              font-weight: 400;
              font-size: 16px;
              line-height: 19px;
              color: #fff;
              text-decoration: none;
            }
          }
        }

        .link__title {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 36px;
          color: #ffffff;
          padding-bottom: 6px;
          margin-right: 40px;
        }
      }
    }

    .copy_rights {
      border-top: 1px solid #ffffff90;
      margin-top: 40px;
      padding: 30px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22.4px;
        color: #fff;
      }
    }
  }
  .scrollToTop {
    position: absolute;
    bottom: 80px;
    right: 40px;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: #2563ec;
    width: 30px;
    height: 30px;
    border-radius: 4px;

    .icon {
      width: 16px;
      height: 16px;
    }
  }

  @media only screen and (max-width: 1440px) {
    padding: 0 60px;
    max-width: 1440px;
    .scrollToTop {
      right: 20px;
    }
  }

  @media only screen and (max-width: 1300px) {
    .footer__container {
      .footerHeaderContent {
        .links_block {
          .social,
          .contact {
            width: auto;
          }
          .social {
            margin-right: 40px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1111px) {
    .footer__container {
      .footerHeaderContent {
        grid-template-columns: 60% 40%;
        .links_block {
          flex-direction: column;
          .social {
            margin-right: 0;
            margin-bottom: 40px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 920px) {
    .footer__container {
      .footerHeaderContent {
        display: flex;
        flex-direction: column;
        .blogSection {
          .blogDescription {
            max-width: 100%;
          }
        }
        .links_block {
          padding-left: 0;
        }
        .social {
          margin-top: 40px;
        }
      }
      .copy_rights {
        flex-direction: column;
        align-items: flex-start;
        .logo {
          margin-bottom: 10px;
        }
        p {
          font-size: 14px;
        }
      }
    }
  }
  @media only screen and (max-width: 550px) {
    .footer__container {
      .footerHeaderContent {
        .blogSection {
          .row {
            flex-direction: column;
            .blogImage {
              width: 100%;
              height: inherit;
              margin-right: 0;
            }
            .column {
              margin-top: 20px;
            }
          }
        }
        .social {
          margin-top: 20px;
          margin-bottom: 20px;
        }
      }
    }
  }

  @media (max-width: 680px) and (orientation: portrait) {
    padding: 0 20px;

    .scrollToTop {
      bottom: 70px;
    }
  }
  @media only screen and (max-width: 422px) {
    .scrollToTop {
      bottom: 90px;
    }
  }
}
