.appGrid {
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto auto;
  column-gap: 10px;
  row-gap: 10px;
  justify-content: center;

  .application {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #eff6ff;
    width: 230px;
    height: 150px;
    border-radius: 8px;
    padding: 20px;
    position: relative;

    .appIcon {
      height: 40px;
      margin-bottom: 10px;
    }
    .appName {
      font-family: "Poppins";
      font-size: 14px;
      font-weight: 600;
      line-height: 15.4px;
      text-align: center;
    }

    .optionalText {
      font-family: "Poppins";
      font-size: 10px;
      font-weight: 500;
      line-height: 12.8px;
      text-align: center;
      color: #64748b;
      position: absolute;
      bottom: 24px;
    }
  }

  @media only screen and (max-width: 1080px) {
    grid-template-columns: auto auto auto;
  }
  @media only screen and (max-width: 830px) {
    grid-template-columns: auto auto;
  }

  @media only screen and (max-width: 530px) {
    .application {
      width: 180px;
      height: 110px;
      padding: 14px;

      .appIcon {
        height: 30px;
      }

      .appName {
        font-size: 10px;
        line-height: 12px;
      }

      .optionalText {
        bottom: 14px;
        font-size: 8px;
      }
    }
  }

  @media only screen and (max-width: 425px) {
    .application {
      width: 100%;
    }
  }
}
