.Home--Root {
  position: relative;

  .headerContainer {
    width: 100%;
    box-sizing: border-box;

    .content {
      height: 100%;
      max-width: 1440px;
      padding: 30px 120px 0 120px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      position: relative;

      .textArea {
        padding-top: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        .title {
          font-family: "Poppins";
          font-style: normal;
          text-align: center;
          max-width: 790px;
          font-weight: 700;
          font-size: 48px;
          line-height: 52.8px;
          margin-bottom: 32px;
          color: #0f172a;
        }
        .subtitle {
          font-family: "Poppins";
          font-style: normal;
          text-align: center;
          font-weight: 700;
          font-size: 18px;
          line-height: 18px;
          letter-spacing: 0.5px;
          color: #94a3b8;
          margin-bottom: 20px;
          span {
            font-weight: 600;
            font-style: italic;
            text-decoration: underline;
          }
        }
        .primaryButton {
          background-color: #2563ec;
          text-decoration: none;
          border-radius: 40px;
          outline: none;
          border: none;
          cursor: pointer;
          font-family: "Poppins";
          font-weight: 600;
          font-size: 18px;
          line-height: 18px;
          color: #fff;
          padding: 16px 24px;
          margin-bottom: 80px;
        }
      }
      .homepageLanding {
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        border: 8px solid #1e293b;
        border-style: solid solid none solid;
        width: 100%;
        height: inherit;
      }
    }

    @media only screen and (max-width: 1440px) {
      .content {
        padding: 30px 60px 0 60px;
        .textArea {
          .primaryButton {
            margin-bottom: 60px;
          }
        }
      }
    }
    @media only screen and (max-width: 1000px) {
      height: 100%;
      .content {
        flex-direction: column;
      }
    }
    @media only screen and (max-width: 920px) {
      .content {
        .textArea {
          .title {
            font-size: 40px;
            max-width: max-content;
          }
        }
      }
    }
    @media only screen and (max-width: 600px) {
      .content {
        padding: 30px 30px 0 30px;

        .textArea {
          .title {
            font-weight: 700;
            font-size: 32px;
            line-height: 48px;
          }
          .subtitle {
            font-weight: 400;
            font-size: 18px;
            line-height: 28px;
          }
        }
      }
    }
  }
  .section2 {
    margin: 0 auto;
    display: flex;
    justify-content: center;

    .content {
      width: 100%;
      max-width: 1440px;
      padding: 60px 120px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .sectionTitle {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 48px;
        letter-spacing: -0.1px;
        color: #1e293b;
        margin-bottom: 12px;
        text-align: center;
      }
      .sectionSubtitle {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        color: #0f172a;
        margin-bottom: 60px;
      }
    }
    @media only screen and (max-width: 1440px) {
      .content {
        padding: 60px;
      }
    }
    @media only screen and (max-width: 600px) {
      .content {
        padding: 40px 30px;
      }
    }

    @media only screen and (max-width: 530px) {
      .content {
        .sectionTitle {
          font-size: 32px;
          line-height: 38px;
          letter-spacing: -0.1px;
        }
        .sectionSubtitle {
          font-size: 16px;
          line-height: 22px;
          margin-bottom: 40px;
        }
      }
    }
  }
  .section3 {
    background-color: #2563ec;
    padding-right: 0px;
    display: flex;
    justify-content: center;
    .section-container {
      padding: 60px 120px;
      padding-top: 100px !important;
      max-width: 1440px;
      width: 100%;
      display: flex;
      gap: 96px;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .our-pricing-section-title {
        color: #fff;
        text-align: center;
        font-family: Poppins;
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%; /* 57.6px */
        letter-spacing: -0.96px;
      }
      .pricing-cards {
        display: flex;
        align-items: flex-start;
        gap: 96px;
        .card-1 {
          margin-top: 176px;
        }
        .card-3 {
          margin-top: 176px;
        }
        .pricing-card {
          overflow: hidden;
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 20px;
          background-color: white;
          max-width: 325px;
          min-width: 200px;
          width: 325px;
          padding: 24px 20px 27px 20px;
          border-radius: 13.54px;
          .popular-label {
            position: absolute;
            top: 18px;
            right: -38px;
            transform: rotate(30deg);
            font-family: Poppins;
            font-size: 14px;
            font-weight: 500;
            background-color: black;
            color: white;
            padding: 4px;
            width: 175px;
            text-align: center;
          }
          .tag {
            font-size: 20px;
            font-weight: 600;
            color: #2563ec;
            background-color: #2563ec2e;
            border-radius: 23.7px;
            padding: 7px 21px;
            width: fit-content;
          }
          .price {
            font-size: 27px;
            font-family: Poppins;
            font-weight: 700;
          }
          hr {
            width: 100%;
            border-color: #d9d9d9;
          }
          .features {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 12px;
            .feature {
              display: flex;
              gap: 10px;
              img {
                width: 24px;
                height: 24px;
              }
              p {
                font-size: 17px;
              }
            }
          }
          .request-cta {
            text-align: center;
            text-decoration: none;
            width: 100%;
            cursor: pointer;
            outline: none;
            border: 2px solid #2563ec;
            border-radius: 33px;
            font-size: 16px;
            font-weight: 700;
            color: #2563ec;
            padding: 14px 7px;
            font-family: Helvetica;
            transition: all 200ms;
            background-color: white;
          }
          .request-cta:hover {
            opacity: 0.8;
          }
        }
      }
      .createWorkflow-image-new {
        max-width: 425px;
        margin-top: -200px;
        border: 8px solid #1e293b;
        border-radius: 36px;
        background-repeat: no-repeat; /* Prevent the image from repeating */
        background-size: contain;
        background-position: center;
        height: inherit;
      }
      .pricing-section-footer {
        img {
          width: 22px;
          height: 22px;
        }
        p {
          color: #fff;

          text-align: center;
          font-family: Poppins;
          font-size: 16.23px;
          font-style: normal;
          font-weight: 500;
          line-height: 141%;
        }
        display: flex;
        max-width: 840px;
      }
      .pricing-section-footer-mobile {
        display: none;
      }
    }

    .content {
      margin-right: 10px;
      background-color: white;
      padding: 40px 30px;
      border-radius: 20px;
      .list-item-container {
        margin: 30px 0px;
        .list-item {
          display: flex;
          align-items: center;
          img {
            width: 18px;
            height: 18px;
            margin-right: 10px;
          }
          .list-item-text {
            display: flex;
            flex-wrap: wrap;
          }
          margin: 12px 0 5px 0;
        }
      }
      .subsection-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .subsection {
          display: flex;
          align-items: center;
          h1 {
            font-size: 48px;
            margin-right: 10px;
          }
          p {
            font-size: 18px;
            max-width: 130px;
            font-weight: bold;
          }
        }
        img {
          margin: 0 20px;
        }
      }
      .demoButtonLink {
        background-color: #2563ec;
        text-decoration: none;
        border-radius: 40px;
        outline: none;
        border: none;
        cursor: pointer;
        font-family: "Poppins";
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        color: #fff;
        padding: 12px 20px;
        margin-bottom: 30px;
      }
      button {
        font-size: 16px;
        padding: 12px 30px;
        background-color: #2563ec;
        color: white;
        border-radius: 25px;
        outline: none;
        cursor: pointer;
        border: none;
      }
    }
    .createWorkflow-image {
      margin-left: 10px;
      border: 8px solid #1e293b;
      border-radius: 36px;
      background-repeat: no-repeat; /* Prevent the image from repeating */
      background-size: contain;
      background-position: center;
      width: 578px;
      height: inherit;
    }
  }

  @media only screen and (max-width: 1440px) {
    .section3 {
      .section-container {
        padding: 60px;
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    .section3 {
      padding-bottom: 0px;
      .content {
        padding: 30px 20px;
        .list-item-container {
          margin: 20px 0;
        }
        .subsection-container {
          .subsection {
            h1 {
              font-size: 36px;
            }
            p {
              font-size: 14px;
            }
          }
          img {
            margin: 0 10px;
          }
        }
        button {
          font-size: 14px;
          padding: 8px 22px;
          border-radius: 15px;
        }
      }
      .section-container {
        padding: 60px 120px;
        padding-top: 100px !important;
        max-width: 1440px;
        width: 100%;
        display: flex;
        gap: 60px;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .our-pricing-section-title {
          color: #fff;
          text-align: center;
          font-family: Poppins;
          font-size: 48px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%; /* 57.6px */
          letter-spacing: -0.96px;
        }
        .pricing-cards {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          gap: 60px;
          .card-1 {
            margin-top: 0;
          }
          .card-3 {
            margin-top: 0;
          }
          .pricing-card {
            overflow: hidden;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: start;
            gap: 20px;
            background-color: white;
            max-width: 100%;
            min-width: 200px;
            width: 100%;
            padding: 24px 20px 27px 20px;
            border-radius: 13.54px;
            .popular-label {
              position: absolute;
              top: 18px;
              right: -38px;
              transform: rotate(30deg);
              font-family: Poppins;
              font-size: 14px;
              font-weight: 500;
              background-color: black;
              color: white;
              padding: 4px;
              width: 175px;
              text-align: center;
            }
            .tag {
              font-size: 20px;
              font-weight: 600;
              color: #2563ec;
              background-color: #2563ec2e;
              border-radius: 23.7px;
              padding: 7px 21px;
              width: fit-content;
            }
            .price {
              font-size: 27px;
              font-family: Poppins;
              font-weight: 700;
            }
            hr {
              width: 100%;
              border-color: #d9d9d9;
            }
            .features {
              width: 100%;
              display: flex;
              flex-direction: column;
              gap: 12px;
              .feature {
                display: flex;
                gap: 10px;
                img {
                  width: 24px;
                  height: 24px;
                }
                p {
                  font-size: 17px;
                }
              }
            }
            .request-cta {
              width: 100%;
              cursor: pointer;
              outline: none;
              border: 2px solid #2563ec;
              border-radius: 33px;
              font-size: 16px;
              font-weight: 700;
              color: #2563ec;
              padding: 14px 7px;
              font-family: Helvetica;
              transition: all 200ms;
              background-color: white;
            }
            .request-cta:hover {
              opacity: 0.8;
            }
          }
        }
        .createWorkflow-image-new {
          max-width: 100%;
          margin-top: 0;
        }
        .pricing-section-footer-desktop {
          display: none;
        }
        .pricing-section-footer-mobile {
          display: flex;
        }
      }
    }
  }
  @media only screen and (max-width: 900px) {
    .section3 {
      padding-bottom: 0px;
      .section-container {
        padding: 60px 60px 60px 60px;
      }
      .content {
        margin-right: 0px;
        width: 100%;
      }
      .createWorkflow-image {
        max-height: 480px !important;
      }
    }
  }
  @media only screen and (max-width: 700px) {
    .section3 {
      .content {
        max-width: 575px;
      }
      .createWorkflow-image {
        max-height: 400px !important;
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .section3 {
      .section-container {
        padding: 30px 30px 30px 30px;
      }
      .content {
        .subsection-container {
          flex-direction: column;
          align-items: flex-start;
          padding: 5px 0px;
          img {
            margin: 0px 25px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 550px) {
    .section3 {
      .section-container {
        .createWorkflow-image {
          max-height: 350px !important;
        }
      }
    }
  }
  @media only screen and (max-width: 500px) {
    .section3 {
      .section-container {
        .createWorkflow-image {
          max-height: 300px !important;
        }
      }
    }
  }
  @media only screen and (max-width: 450px) {
    .section3 {
      .section-container {
        .createWorkflow-image {
          max-height: 250px !important;
        }
      }
    }
  }
  .mainArea {
    display: flex;
    flex-direction: column;
    padding: 40px 0;
    width: 100%;

    .row {
      width: 100%;
      display: flex;
      align-items: stretch;
      justify-content: space-between;

      .sample {
        width: 50%;
        background: #f1f1f1;
        border-radius: 16px;
        margin-bottom: 30px;
        padding: 40px 30px;

        .title {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 36px;
          color: #0c0433;
          margin-bottom: 15px;
        }
        .subtitle {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: #0c0433;
          margin-bottom: 16px;
        }
        .appsArea {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-wrap: wrap;

          .appIcon,
          .arrowRight {
            margin: 0 10px 10px 0;
          }
        }
        .appsStep {
          padding: 10px 0;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-wrap: inherit;
          img {
            margin: 5px;
            max-height: 35px;
          }

          .appIcon,
          .arrowRight {
            margin: 0 10px 10px 0;
          }
        }
      }
      .one {
        margin-right: 30px;
      }
    }
    .moreInfo {
      width: 100%;
      background: #34baf7;
      border-radius: 16px;
      padding: 40px 30px;
      display: flex;
      align-items: center;

      .icon {
        margin-right: 30px;
      }

      .column {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .title {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 36px;
          color: #ffffff;
          margin-bottom: 16px;
        }
        .subtitle {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: #ffffff;
        }
      }
    }
    @media only screen and (max-width: 900px) {
      padding: 20px 0 40px 0;
      .row {
        flex-direction: column;
        .sample {
          width: 100%;
          min-height: auto;
        }
      }
    }
    @media only screen and (max-width: 600px) {
      .moreInfo {
        flex-direction: column;
        .icon {
          margin-right: 0;
          margin-bottom: 30px;
        }
      }
    }
  }
  .section4 {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    max-width: 1440px;

    .content {
      width: 100%;
      padding: 60px 120px;
      .content-container {
        .sectionTitle {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 48px;
          color: #0c0433;
          text-align: center;
          margin-bottom: 16px;
        }
        .sectionSubtitle {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: #0c0433;
          text-align: center;
          margin-bottom: 14px;
        }
      }

      .teamsGrid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(322px, 1fr));
        gap: 10px; /* Adjust gap between grid items */
        max-width: 1440px; /* 380px * 3 = 1140px for three items */
        margin: 0 auto; /* Center the grid */

        .memberItem {
          max-width: 380px;
          .image {
            width: 100%;
            border-radius: 8px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            margin-bottom: 10px;
          }
          .memberName {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
            color: #0c0433;
            margin-bottom: 4px;
          }
          .memberJob {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #adaeb6;
            margin-bottom: 10px;
          }
        }
      }
    }
    @media only screen and (max-width: 1440px) {
      .content {
        padding: 60px;
      }
    }

    @media only screen and (max-width: 1440px) {
      .teamsGrid {
        justify-items: center;
      }
    }

    @media only screen and (max-width: 600px) {
      .content {
        padding: 40px 30px;
      }
    }
  }
  .section7 {
    margin: 0 auto;
    max-width: 1440px;
    .title {
      font-size: 36px;
      text-align: center;
      padding: 0 30px;
      margin-bottom: 30px;
    }
    .accordion {
      padding: 60px 120px;
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 1440px;
    }
    @media only screen and (max-width: 1440px) {
      .accordion {
        padding: 60px 60px;
      }
    }
    @media only screen and (max-width: 600px) {
      .accordion {
        padding: 30px 30px;
      }
    }
    @media only screen and (max-width: 575px) {
      .title {
        margin-bottom: 20px;
      }
    }
  }
}
