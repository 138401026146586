.form {
  width: 100%;
  box-sizing: border-box;
  position: relative;
  z-index: 5;

  .landingBackground3 {
    position: absolute;
    top: -70px;
    left: -70px;
  }
  .logoArea {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }

  .formHeader {
    text-align: center;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #0c0433;
    margin-bottom: 24px;
  }

  .inputArea {
    width: 100%;
    margin-bottom: 24px;
    position: relative;

    .inputField {
      background: #f5f5f5;
      border-radius: 8px;
      border: none;
      outline: none;
      color: #0c0433;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      width: 100%;
      height: 55px;
      padding: 20px 2px 20px 55px;

      ::placeholder {
        color: #adaeb6;
        opacity: 1; /* Firefox */
      }

      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #adaeb6;
      }

      ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #adaeb6;
      }
    }
    .icon {
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .primaryButton {
    width: 100%;
    padding: 20px 24px;
    outline: none;
    border: none;
    cursor: pointer;
    background: #34baf7;
    border-radius: 8px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 14px;
    margin-bottom: 24px;
    color: #ffffff;
  }
  .primaryButton:disabled,
  .primaryButton[disabled] {
    background: #34baf788;
    cursor: not-allowed;
  }
  .alignCenter {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .grayText {
    width: 350px;
    text-align: center;
    font-family: "Futura";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    color: #0c0433;
    opacity: 0.5;
  }
  .errorMessage {
    text-align: center;
    font-family: "Futura";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    color: #fd7062;
    opacity: 0.8;
    height: 14px;
    margin-bottom: 16px;
  }
}
